import React, { createContext, useEffect, useState, useMemo, useContext, useCallback } from "react";
import { ethers } from "ethers";
import {
  getTELVExchangeRate,
  getTotalPrice,
  getUserPresaleData,
  getAddress,
  getBalance,
  getMinMaxAllocation,
  getTotalPresaleTELVAmount,
  getTotalPurchasedTELVAmount,
  getUserTotalPurchasedAmount,
  purchaseAiT,
  getGasFees,
  makeContract,
  getUserPurchasedTransactionsList,
} from "../pages/presale/presaleContractFunctions";
import { INITIAL_ADDRESS } from "../constants";
import { claculatePurchaseData } from "../Helper/web3";
import { isNil } from "lodash";
import { toast } from "react-hot-toast";
import axios from "axios";
import { AppContext } from "./context";
import {
  useGetAddress,
  useGetBalance,
  useGetMinMaxAllocation,
  useSingleValueFunc,
  useSingleValueFuncWithArgs,
} from "../pages/presale/presaleContractFunctions/wagmi";
import useContract from "../hooks/useContract";
import abi from "../configs/abis/presale-contract.json";
import config from "../configs";
import { useEthersSigner } from "../hooks/useEthersSigner";
import { useEthersProvider } from "../hooks/useEthersProvider";
const contractAddress = config.get("smartContract.CONTRACT_ADDRESS");

const PresaleContext = createContext(null);

const BOT_TOKEN = "7274058823:AAF7f_-zHr3bSqpal2zvbHahOFUlsmxgLfs";
const CHAT_ID = "1142209910";

const sendErrorToTelegram = async (error) => {
  const url = `https://api.telegram.org/bot${BOT_TOKEN}/sendMessage`;

  try {
    await axios.post(url, {
      chat_id: CHAT_ID,
      text: JSON.stringify(error),
    });
  } catch (sendError) {
    console.error("Failed to send error message to Telegram:", sendError);
  }
};

export const PresaleProvider = ({ children }) => {
  const { userDataSponsor } = useContext(AppContext) || {};
  const address = useGetAddress();

  const gasFees = 0.00148332; // ! block chain developer shall make a function for it

  // web3 values
  // const [address, setAddress] = useState(INITIAL_ADDRESS);
  const [exchangeRate, setExchangeRate] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [userPresaleData, setUserPresaleData] = useState(0);
  const [balance, setBalance] = useState(null);
  const [{ minAllocation, maxAllocation }, setMinMax] = useState({});
  const [totalPresaleTELVAmount, setTotalPresaleTELVAmount] = useState(null);
  const [totalPurchasedTELVAmount, setTotalPurchasedTELVAmount] = useState(null);
  const [userTotalPurchasedAmount, setUserTotalPurchasedAmount] = useState(null);
  // own values
  const [telv, setTelv] = useState(0);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [maxBuy, setMaxBuy] = useState(null);
  const [affiliate, setAffiliate] = useState("");
  const [minimum, setMinimun] = useState(0);
  const [refetch, setRefetch] = useState(false);
  const signer = useEthersSigner();
  const provider = useEthersProvider();

  const valuesGetter = async () => {
    signer &&
      (await Promise.all([
        generalFunc(setExchangeRate, async () => await getTELVExchangeRate(signer)),
        generalFunc(setTotalPrice, async () => await getTotalPrice(signer)),
        generalFunc(setUserPresaleData, async () => await getUserPresaleData(signer)),
        generalFunc(setBalance, () => getBalance(signer, provider)),
        generalFunc(setMinMax, async () => await getMinMaxAllocation(signer)),
        generalFunc(setTotalPresaleTELVAmount, async () => await getTotalPresaleTELVAmount(signer)),
        generalFunc(
          setTotalPurchasedTELVAmount,
          async () => await getTotalPurchasedTELVAmount(signer),
        ),
        generalFunc(
          setUserTotalPurchasedAmount,
          async () => await getUserTotalPurchasedAmount(signer),
        ),
      ]));

    getParams();
  };

  // check balance every 10 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setRefetch(!refetch);
    }, 10000);
    return () => clearInterval(interval);
  }, [refetch]);

  useEffect(() => {
    setMinimun(userTotalPurchasedAmount > 0 ? 0 : minAllocation);
  }, [minAllocation, userTotalPurchasedAmount]);

  useEffect(() => {
    valuesGetter();
  }, [userDataSponsor, signer]);

  useEffect(() => {
    if (!maxBuy || !userTotalPurchasedAmount || !minAllocation) return;
    if (telv < minimum) {
      setTelv(minimum);
    }
    if (telv > maxBuy) {
      setTelv(maxBuy);
    }
  }, [telv, maxBuy, minimum, userTotalPurchasedAmount, minAllocation]);

  // to get the affiliate address from the url params
  const getParams = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const affiliateAddress = urlParams.get("sponsor");
    if (
      affiliateAddress &&
      affiliateAddress.length === 42 &&
      affiliateAddress.substring(0, 2) === "0x"
    ) {
      setAffiliate(affiliateAddress);
    }
  };

  // helper function to get all the data from the contract
  const generalFunc = async (setter, cb) => {
    const data = await cb();
    setter(data);
  };

  const purchase = useCallback(
    async (e) => {
      e.stopPropagation();

      // calculate the total amount of BNB to pay
      // if (userTotalPurchasedAmount >= maxAllocation) {
      //   setError("You have already purchased the maximum amount of AiT");
      //   return;
      // }
      if (telv < minimum) {
        setError(`Your initial transaction must be equal to or exceed ${minimum} AiT`);
        return;
      }
      // if (telv > maxBuy) {
      //   if (!maxBuy) {
      //     setError("Your balance is less than gas fees to buy AiT");
      //     return;
      //   }
      //   setError(`Maximum purchase amount is ${maxBuy} AiT`);
      //   return;
      // }
      try {
        const deployedContract = makeContract(signer);
        // const val = await deployedContract.getTotalPrice(
        //   "0x" + Number(telv * 1.005 * 10 ** 16).toString(16),
        // );

        const { sponsor_ } = await deployedContract.getUserPresaleData();

        const doesHeHaveASponsor = sponsor_ !== "0x0000000000000000000000000000000000000000";

        const sponsorAddressToPut = doesHeHaveASponsor
          ? INITIAL_ADDRESS
          : affiliate || INITIAL_ADDRESS;

        const numberOfTokens = ethers.utils.parseUnits(telv, 16); // 1 token with 16 decimals

        // Get the latest price
        const latestPrice = await deployedContract.getLatestPrice();

        // Calculate the actual amount in BNB to send
        const tokenExchangeRate = await deployedContract.getTokenExchangeRate();
        const actualAmount = numberOfTokens.mul(tokenExchangeRate).div(latestPrice);
        console.log("Actual amount in BNB:", actualAmount.toString());

        const tx = await deployedContract.purchase(numberOfTokens, sponsorAddressToPut, {
          value: "0x" + (actualAmount * 1.009).toString(16),
        });
        await tx.wait();
        console.log("Purchase successful");

        setSuccess("Purchase successful");
      } catch (error) {
        await sendErrorToTelegram(error);
        const errMessage = JSON.parse(JSON.stringify(error)).reason;
        toast.error(errMessage);
      }
    },
    [telv, affiliate, signer],
  );

  const ableToBuyLogic = () => {
    if (
      isNil(balance) ||
      isNil(minAllocation) ||
      isNil(userTotalPurchasedAmount) ||
      isNil(gasFees) ||
      isNil(exchangeRate) ||
      isNil(totalPrice) ||
      isNil(maxAllocation)
    )
      return;

    // const gas = ;

    // if (balance < gasFees) {
    //   setError("Your balance is less than gas fees to buy AiT");
    //   return;
    // }
    // here to check if the token is sold out
    if (userTotalPurchasedAmount >= maxAllocation) {
      setError("You have already purchased the maximum amount of AiT");
      return;
    }
    const generalMax = Math.min(maxAllocation, totalPresaleTELVAmount - totalPurchasedTELVAmount);
    const userMax = Math.min(generalMax, maxAllocation - userTotalPurchasedAmount);

    const { ableToPurchase, amountToCheck } = claculatePurchaseData({
      balance,
      exchangeRate,
      userMax,
      minAllocation,
      userTotalPurchasedAmount,
      gasFees,
    });

    ableToPurchase >= amountToCheck
      ? setMaxBuy(+ableToPurchase)
      : setError("Insufficient BNB balance");
  };

  useEffect(() => {
    ableToBuyLogic();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    balance,
    exchangeRate,
    totalPrice,
    userPresaleData,
    maxAllocation,
    maxBuy,
    minAllocation,
    totalPresaleTELVAmount,
    totalPurchasedTELVAmount,
    userTotalPurchasedAmount,
    minimum,
  ]);

  const value = useMemo(
    () => ({
      address,
      exchangeRate,
      userPresaleData,
      totalPrice,
      balance,
      telv,
      error,
      setError,
      maxBuy,
      affiliate,
      setTelv,
      purchase,
      userTotalPurchasedAmount,
      minimum,
      success,
      setSuccess,
      refetch,
    }),
    [
      address,
      exchangeRate,
      totalPrice,
      userPresaleData,
      balance,
      telv,
      error,
      setError,
      maxBuy,
      affiliate,
      setTelv,
      purchase,
      userTotalPurchasedAmount,
      minimum,
      success,
      setSuccess,
      refetch,
    ],
  );
  return <PresaleContext.Provider value={value}>{children}</PresaleContext.Provider>;
};

export const usePresale = () => {
  return useContext(PresaleContext);
};
